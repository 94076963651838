import { Button } from '@/components/ui/button'
import { cls } from '@/lib/cls'
import { BACK_LINK_SEARCH_PARAM, IdpInitResponseBody } from '@/model/auth/api-types'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ApiError } from './errors'

type LoginState = { state: 'initial' } | { state: 'loading' } | { state: 'error'; error: string } | { state: 'success' }

export const LoginButton = ({ loading }: { loading?: boolean }) => {
	const searchParams = useSearchParams()!
	const shouldInit = searchParams.get('init') === '1'

	const [state, setState] = useState<LoginState>({ state: 'initial' })
	const login = useCallback(async () => {
		setState({ state: 'loading' })
		try {
			const result = await fetch('/api/idp-init', {
				method: 'POST',
			})
			const res = IdpInitResponseBody.parse(await result.json())

			// intentionally not setting success state, because the user will be redirected
			// setState({ state: 'success' })
			if (searchParams.has(BACK_LINK_SEARCH_PARAM) && searchParams.get(BACK_LINK_SEARCH_PARAM)) {
				localStorage.setItem(`login${BACK_LINK_SEARCH_PARAM}`, String(searchParams.get(BACK_LINK_SEARCH_PARAM)))
			}
			window.location.href = res.result.url
		} catch (e: any) {
			// eslint-disable-next-line no-console
			console.error(e)
			setState({ state: 'error', error: e.message })
		}
	}, [searchParams])

	const initialized = useRef(false)
	useEffect(() => {
		if (!initialized.current && shouldInit) {
			;(async () => {
				initialized.current = true
				await login()
			})()
		}
	}, [login, shouldInit])

	if (state.state === 'error') {
		return (
			<ApiError
				message={
					<>
						You shall not pass! We are doing everything we can to allow you to pass this screen.
						<br />
						Try again in a few minutes.
					</>
				}
				error={state.error}
			/>
		)
	}

	return (
		<Button onClick={login} className={cls('px-8 py-3 flex gap-3')}>
			<Image
				className={cls((state.state === 'loading' || loading) && 'animate-spin')}
				src="/icon/contember-icon.svg"
				alt={''}
				width={22}
				height={22}
			/>
			<span>{state.state === 'loading' || loading ? 'Redirecting...' : 'Sign in with Contember'}</span>
		</Button>
	)
}
